import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Box, { BoxImage, BoxParagraph, BoxTitle } from '../elements/box';
import Card, { CardContent, CardImage } from '../elements/card';
import Diapo from '../elements/diapo';

const Presentation = () => (
  <Layout>
    <Box background="primary-light">
        <BoxTitle title="Mes formations" />
        <BoxParagraph>
            <p className="title is-6">Mon travail de thérapeute s'articule autour d'une pratique intégrative :</p>
        </BoxParagraph>
        <div className="columns">
            <div className="column">
                <a href="https://www.epg-gestalt.fr">
                    <Card>
                        <CardContent>
                            <p>Superviseure de thérapeutes et Gestalt-thérapeute formée à l'EPG :</p>
                        </CardContent>
                        <CardImage src="logo_epg" alt="logo EPG" hoverable="true" size="is-3by1" />
                    </Card>
                </a>
            </div>
            <div className="column">
                <a href="https://www.forsyfa.com">
                    <Card>
                        <CardContent>
                            <p>Intervention systémique formée à FORSYFA :</p>
                        </CardContent>
                        <CardImage src="logo_forsyfa" alt="logo Forsyfa" hoverable="true" size="is-3by1" />
                    </Card>
                </a>
            </div>
            <div className="column">
                <a href="https://www.gestalt-ifgt.com/?Le-mouvement-notre-premier-langage">
                    <Card>
                        <CardContent>
                            <p>Developmental Somatic Psychotérapy formée à l’IFGT :</p>
                        </CardContent>
                        <CardImage src="logo_ifgt" alt="logo IFGT" hoverable="true" size="is-3by1" />
                    </Card>
                </a>
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <a href="https://massagesgm.com">
                    <Card>
                        <CardContent>
                            <p>Praticienne en Gestalt Massage® formée à l’IFFP :</p>
                        </CardContent>
                        <CardImage src="logo_ifpp_massage" alt="logo IFPP massage" hoverable="true" size="is-3by1" />
                    </Card>
                </a>
            </div>
            <div className="column">
                <a href="https://www.lienspsy.com/">
                    <Card>
                        <CardContent>
                            <p>Formée aux Constellations Familiales par LiensPsy :</p>
                        </CardContent>
                        <CardImage src="logo_liensPsy" alt="logo liensPsy" hoverable="true" size="is-3by1" />
                    </Card>
                </a>
            </div>
            <div className="column">
                <a href="http://ecoleducouple.com/">
                    <Card>
                        <CardContent>
                            <p>Formée à l'accompagnement des couples par l'Ecole du Couple :</p>
                        </CardContent>
                        <CardImage src="logo_ecoleDuCouple" alt="logo ecole du couple" hoverable="true" size="is-3by1" />
                    </Card>
                </a>
            </div>
        </div>
        <BoxParagraph>
            <p>Cette pratique intégrative m’a donné une approche holistique de la personne et plus que jamais j’ai la sensation 
                comme l’a dit Michel Ange « que c’est la sculpture qui fait émerger la forme ». C’est dans la rencontre et dans 
                cet espace de co création que je vois la dimension artistique du processus thérapeutique.</p>
        </BoxParagraph>
    </Box>
    <Box>
        <div className="columns">
            <div className="column is-3">
                <BoxImage src="nportrait4" alt="portrait" rounded={true} />
            </div>
            <div className="column">
                <BoxTitle title="La rencontre individuelle en Gestalt" />
                <BoxParagraph>
                    <p>Dans la rencontre, il y le client, il y a moi et ce « nous » à partir duquel nous allons ébaucher, sculpter, 
                        travailler, faire émerger une forme, des formes. C’est un espace qui m’émeut et me passionne parce que c’est un 
                        moment précieux où une personne a le courage de se dire et me laisser rentrer dans son intime.</p>
                    <p>Je ne sais jamais comment cela va se passer, c’est nouveau avec chacun et à chaque fois, c’est vivant et cela 
                        me permet d’être créative, cela m’oblige à être créative.</p>
                </BoxParagraph>
            </div>
            <div className="column is-3">
                <BoxImage src="main_photo" alt="main" rounded={true} />
            </div>
        </div>
    </Box>
    <Box background="primary-light">
        <div className="columns">
            <div className="column">
                <BoxTitle title="La place du corps dans ma pratique" />
                <BoxParagraph>
                    <p><em>« Le corps est une rivière, et non une structure fixe ou une forme pétrifiée. Il est en perpétuelle transformation. »
                        <br />Daniel DUFOUR dans Les tremblements intérieurs</em></p>
                    <p>J’ai toujours été fascinée par le langage du corps, issue d’une relation biculturelle entre l’Inde et l’Espagne, 
                    le toucher est présent pendant toute mon enfance. Ma mère ne parle pas beaucoup, mais elle me touche, me témoigne 
                    énormément de tendresse, me prend dans ses bras, me réconforte et me chérit. C’est essentiellement par ce canal-là, 
                    celui du corps que nous allons communiquer.</p>
                    <p>La conception holistique se fonde sur le principe que le « tout » est différent ou plus grand de la somme des parties. La perspective dualiste traditionnelle définit « la personne » comme une série de partie regroupées : le corps, qui est lui-même un rassemblement de parties, l’esprit… Considérer l’individu comme un tout plus grand que la somme de ses parties, c’est le considérer dans la totalité de ses parties, c’est-à-dire le corps, l’esprit, la pensée, les ressentis, l’imagination…, tout en étant différent de ses parties. C’est le fonctionnement intégré de ces différentes parties du tout dans le temps et dans l’espace qui constitue la personne. Donc se contenter de regarder la personne sous une de ses parties uniquement revient à la fragmenter et la réduire à cette unique partie.</p>
                    <p>Observer, nommer, soutenir la conscience et la remobilisation, co-créer de nouvelles possibilités d’ajustement 
                    de l’être corps sera un axe du travail thérapeutique. Mettre son attention sur les dissonances entre le discours, 
                    et le langage corporel pour trouver un chemin…</p>
                </BoxParagraph>
            </div>
            <div className="column is-4">
                <BoxTitle />
                <br />
                <Diapo
                        backgroundImages={[ 
                            "main1",
                            "main2",
                            "main3",			
                        ]}
                        alt="mains"
                        size="is-3by4" 
                    />
            </div>
        </div>
        <div className="columns">
            <div className="column"></div>
            <div className="column is-3">
                <Card>
                    <CardImage src="ballon1" alt="ballon" size="is-4by5" />
                </Card>
            </div>
            <div className="column"></div>
            <div className="column is-3">
                <Card>
                    <CardImage src="ballon2" alt="ballon" size="is-4by5" />
                </Card>
            </div>
            <div className="column"></div>
            <div className="column is-3">
                <Card>
                    <CardImage src="ballon3" alt="ballon" size="is-4by5" />
                </Card>
            </div>
            <div className="column"></div>
        </div>
        <BoxParagraph>
            <p className="subtitle is-5 is-family-secondary"><em>Expérimenter l'appui sur</em></p>
        </BoxParagraph>
        <div className="columns">
            <div className="column"></div>
            <div className="column is-3">
                <Card>
                    <CardImage src="ballon4" alt="appui" size="is-4by5" />
                </Card>
            </div>
            <div className="column"></div>
            <div className="column is-3">
                <Card>
                    <CardImage src="appui" alt="appui" size="is-4by5" />
                </Card>
            </div>
            <div className="column"></div>
            <div className="column is-3">
                <Card>
                    <CardImage src="ballon5" alt="appui" size="is-4by5" />
                </Card>
            </div>
            <div className="column"></div>
            
        </div>
    </Box>
    <Box>
        <BoxTitle title="Le groupe en Gestalt" />
        <BoxParagraph>
            <p><em>« Chacun est confronté à la fois à la différence et à la ressemblance, à l’universalité et à l’originalité de 
                l’expérience humaine. »<br />Anne Ginger</em></p>
        </BoxParagraph>
        <BoxParagraph>
            <p>La dynamique de groupe est pour moi un processus de co création et une aventure humaine fantastique, nourrissante 
                et prometteuse. Je me fais l’effet d’être un peintre devant sa palette récoltant toutes les nuances qui vont 
                constituer une œuvre d’art vivante, commune, et en mouvement permanent. </p>
            <p>Le travail thérapeutique en groupe permet :<br/>
                - D’expérimenter avec les autres, les difficultés relationnelles abordées en thérapie individuelle.<br />
                - D’éprouver dans « l’ici et maintenant », avec le soutien du thérapeute et des autres membres du groupe, 
                ses émotions, ses perceptions et son système de croyances.<br />
                - D’explorer des aspects de ses difficultés rencontrées dans sa vie personnelle et professionnelle. Le client 
                travaille en face à face avec le thérapeute et les autres membres du groupe sont témoins et participent à ce 
                travail. A l’issue de cette expérimentation, chaque membre constituant le groupe est invité à donner son feed-back 
                en parlant de ce qu’il a appris, ressenti et en établissant des liens si nécessaire avec sa propre histoire.<br />
                - De se mettre en mouvement : l’action est supérieure à la parole pour entraîner des changements. La névrose est 
                le blocage du processus d’adaptation. A travers l’espace transitionnel du jeu, la spontanéité émerge et les capacités 
                d’adaptation sont restaurées.</p>
            <p>Le groupe est un agent de changement supérieur à la relation duelle, plus puissant, plus rapide.</p>
        </BoxParagraph>
    </Box>
    <Box background="primary-light">
        <BoxTitle title="La Systémie" />
        <BoxParagraph>
            <p>Ma formation en thérapie systémique me permet également d'appréhender les personnes en interaction permanente avec 
                les différentes dynamiques interpersonnelles, familiales, sociales, professionnelles et environnementales qui 
                l'animent.</p>
            <p>L'approche systémique n'est pas une nouvelle science, ni même une nouvelle discipline ; c'est un point de vue 
                original sur la réalité, une méthode pour aborder les phénomènes complexes. Elle permet une vision synthétique 
                des problèmes alors qu'une démarche analytique a longtemps prévalu dans les sciences.</p>
            <p>Parler d’approche, c’est rester dans le flou, et il est indispensable qu’il en soit ainsi sous peine de laisser 
                croire qu’on a inventé un « truc miracle ». On aura l’occasion de vérifier qu’il s’agit davantage d’un ensemble 
                de comportements cohérents, d’une certaine façon de voir, de juger et d’agir. Il s’agit finalement d’une attitude 
                mentale et psychologique.</p>
            <p>La théorie de la communication élaborée par l'École de Palo Alto s'applique à la fois à prendre en compte des 
                processus complexes et à en donner une formalisation rigoureuse. Elle repose sur trois hypothèses :<br />
                - L'essence de la communication réside dans les processus relationnels et interactionnels. On va s'attacher à 
                observer les rapports qu’entretiennent les individus entre eux.<br />
                - Tout comportement humain a une valeur de communication. Toutes les relations peuvent être perçues comme un vaste 
                système de communications qui se répondent et s'impliquent mutuellement.<br />
                - Les troubles psychiques de la personnalité peuvent être ramenés à des perturbations de la communication entre 
                l'individu porteur de symptômes et son entourage.</p>
            <p>Cette approche me permet d’être créative à d’accompagner les couples et les familles tant verbalement qu’en 
                utilisant des « objets flottants », instruments d’ouverture et de compréhension.</p>
        </BoxParagraph>
    </Box>
    <div className="columns">
        <div className="column is-one-quarter is-offset-2">
            <Link to='/la_gestalt'>
            <Card>
                <CardContent>
                <BoxParagraph>
                    <p>Pour en découvrir plus sur la <b>Gestalt</b>.</p>
                </BoxParagraph>
                </CardContent>
                <CardImage src="gestalt" alt="La Gestalt" hoverable="true"/>
            </Card>
            </Link>
        </div>

        <div className="column is-one-quarter is-offset-2">
            <Link to='/la_systemie'>
            <Card>
                <CardContent>
                <BoxParagraph>
                    <p>Pour en découvrir plus sur la <b>Systémie</b>.</p>
                </BoxParagraph>
                </CardContent>
                <CardImage src="systemie" alt="La Systémie" hoverable="true"/>
            </Card>
            </Link>
        </div>
    </div>
    



    
  </Layout>
)

export default Presentation;
