import React from "react";
import { CardContext, CardContextProvider } from "./cardContext";
import MyImage from "./MyImage";

function CardImage({ src, alt , size, hoverable=false, rounded=false, classNames="" }) {
    return (       
        <div className={classNames}>
            <MyImage size={size} src={src} alt={alt} hoverable={hoverable} rounded={rounded} />
        </div>
    );
}

function CardTitle({ title, subtitle }) {
    return (
        <div className="content has-text-centered">
            <p className="title is-4 is-family-secondary centered-content">{title}</p>
            <p className="subtitle is-6">{subtitle}</p>
        </div>
    );
}

function CardParagraph({ children }) {

    const { isOpen } = React.useContext(CardContext);
    let classNames="content justified-content";

    if (!isOpen) {
        classNames += " is-hidden";
    }    

    return (        
        <div className={classNames}>
            { children }
        </div>
    );
}

function CardContent({ children }) {
    return (
        <div className="card-content collapsible-content">
            {children}
        </div>
    );
}

function CardIcon({ icon }) {
    return(
        <div className="content centered-content">
            <span className="icon is-large">
                    <i className={`fas ${icon} fa-7x is-flex`}></i>
            </span>
        </div>

    );
}

function CardButton() {
    const { isOpen, toggleIsOpen } = React.useContext(CardContext);
    let classNameButtonUp = isOpen ?  "button is-primary is-light is-small is-fullwidth" : "is-hidden";
    let classNameButtonDown = isOpen ?  "is-hidden" : "button is-primary is-light is-small is-fullwidth";

    return(
        <>
            <button onClick={toggleIsOpen} className={classNameButtonUp}>
                <div className="content centered-content">
                    <p><em><b>masquer</b></em></p>
                </div>   
            </button>
            <button onClick={toggleIsOpen} className={classNameButtonDown}>
                <div className="content centered-content">
                <p><em><b>découvrir la suite</b></em></p>
                </div>   
            </button>
        </>
    )
}

function Card({ children , height=" heightMax", background=""}) {
    return (
        <CardContextProvider>
            <div className={"card " + height + " has-background-" + background} >
                {children}
            </div>
        </CardContextProvider>
    );
}

export { Card, CardContent, CardImage, CardTitle, CardParagraph, CardIcon, CardButton };
export default Card;